import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Logo from "../assets/zimodetrans.png"
import MyConstant from "../config/MyConstant";
const Layout = (props) => {
    const location = useLocation();
    let [menuState, setMenuState] = useState(false);

    useEffect(() => {
        setMenuState(false)
    }, [location.pathname])
    return (
        <React.Fragment>
            <div className="c-navbar">
                <div className="c-navbar-brand">
                    <Link to={MyConstant.keyList.projectUrl + "/"}>
                        <img className="img-fluid" src={Logo} alt="Logo" />
                        <div className="logo-text theme-text-color">Zimode Solutions</div>
                    </Link>
                </div>
                <div className="c-navbar-toggler">
                    <button className="btn c-navbar-toggle-button" onClick={() => setMenuState(!menuState)}>
                        <i className={`fas fa-${menuState ? "times" : "bars"}`}></i>
                    </button>
                </div>
                <div className={`c-navbar-nav ${menuState ? "open" : ""}`}>
                    <ul>
                        <li class="c-nav-item">
                            <Link to={MyConstant.keyList.projectUrl + "/"} class={`c-nav-link ${location.pathname === MyConstant.keyList.projectUrl + "/" ? "active" : ""}`}>HOME</Link>
                        </li>
                        <li class="c-nav-item">
                            <Link to={MyConstant.keyList.projectUrl + "/partners"} class={`c-nav-link ${location.pathname.includes("/partners") ? "active" : ""}`}>PARTNERS</Link>
                        </li>
                        <li class="c-nav-item">
                            <Link to={MyConstant.keyList.projectUrl + "/contact-us"} class={`c-nav-link ${location.pathname.includes("/contact-us") ? "active" : ""}`}>CONTACT US</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <Outlet />

            <footer>
                <div className="text-center">
                    <div className="address">3135 Kennedy Blvd, Suite 181, North Bergen, NJ 07047</div>
                    <div className="mt-2">
                        <i className="fas fa-envelope"></i> &nbsp;
                        <a href="mailto:supportdesk.theamericas@zimodesolutions.com">Contact us</a>
                    </div>
                </div>
                <hr />
                <div className="rights-reserved">&#169; {new Date().getFullYear()} Zimode Solutions Inc. All rights reserved.</div>
            </footer>
        </React.Fragment >
    )
};

export default Layout;