export default function ContactUs(props) {
    return <div className="contact-us">
        <div className="container py-5">
            <h3 className="theme-text-color">Contact Us</h3>

            <div className="mt-2">
                Zimode Solutions<br/>
                ATT: New Business Relationship<br/>
                #181, 3135 Kennedy Blvd<br/>
                North Bergen, NJ 07047<br/>
            </div>
        </div>
    </div>;
}
