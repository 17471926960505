import Algorithmic from "../assets/Algorithmic.png"
import Production from "../assets/Production.png"
import Finetuned from "../assets/AI.jpg"
import Assistants from "../assets/Assistant.png"
import Translate from "../assets/Translate.png"

export default function Home(props) {
    return <div className="landing">
        <div className="intro">
            We are in the midst of a once in a lifetime shift within organizations we work in.<br /><br />
            Can we leverage LLM's and multi modal models to assist in our organizational journey?
        </div>
        <div className="simple-plan my-5">
            <h1 className="simple-plan-title text-center">Here is a simple plan</h1>
            <div className='parent-box'>
                <div className='child-box d-flex'>
                    <div className='landing-content'>
                        <div>
                            Agents and Actors are algorithmic and elastically scale to support the User community needs.
                        </div>
                        <div>
                            <ul>
                                <li>Algorithmic value is from institutional knowledge.</li>
                                <li>Actors and Agents live in the streams of data and events that flow though your organizational system.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='image'>
                        <img className='img-fluid' src={Algorithmic} />
                    </div>
                </div>
                <div className='child-box d-flex column-reverse-mobile'>
                    <div className='image'>
                        <img className='img-fluid' src={Finetuned} />
                    </div>
                    <div className='landing-content'>
                        <div>
                            Open source LLM's are finetuned with your in-house data, in your preferred compute infrastructure - both CPU and GPU based.
                        </div>
                        <div>
                            <ul>
                                <li>Proprietary data are the basis of your organizations intellectual property.</li>
                                <li>Repurposing data is an archaeological journey - though well worth the investment.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='child-box d-flex'>
                    <div className='landing-content'>
                        <div>
                        Add assistants and tools that assist with the problem domain - just enough to prove the value of the process.
                        </div>
                        <div>
                            <ul>
                                <li>Rightsizing the tools and assistants brings value to the organization.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='image'>
                        <img className='img-fluid' src={Assistants} />
                    </div>
                </div>
                <div className='child-box d-flex column-reverse-mobile'>
                    <div className='image'>
                        <img className='img-fluid' src={Translate} />
                    </div>
                    <div className='landing-content'>
                        <div>
                        LLM's are the language interface that trigger the Agents/Actors/Tools to arrive at the solution.
                        </div>
                        <div>
                            <ul>
                                <li>Agents, Actors and Tools work for the <b>language interface.</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='child-box d-flex'>
                    <div className='landing-content'>
                        <div>
                            Move agent flows to production.
                        </div>
                        <div>
                            <ul>
                                <li>Nothing is more important than movement.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='image'>
                        <img className='img-fluid' src={Production} />
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
