import LabsWhite from "../assets/LabsWhite.png"
import TeqitLogo from "../assets/TeqitLogo.png"

export default function Partners(props) {
    return <div className="partners">
        <div className="d-flex py-5 justify-content-center">
            <div className="box">
                <a href="https://teqbahn.com/">
                    <img className="img-fluid" src={LabsWhite} alt="Teqbahn Labs" />
                </a>
            </div>
            <div className="box">
                <a href="https://teqit.me/">
                    <img className="img-fluid" src={TeqitLogo} alt="Teqit" />
                </a>
            </div>
        </div>
    </div>;
}
