import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MyConstant from "./config/MyConstant";
import Layout from "./layout/Layout";
import './App.css';
import Partners from "./pages/Partners";
import ContactUs from "./pages/ContactUs";

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path={MyConstant.keyList.projectUrl} element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="partners" element={<Partners />} />
        <Route path="contact-us" element={<ContactUs />} />
      </Route>
    </Routes>
  </BrowserRouter>;
}

export default App;
